import React, { useMemo, useState, useEffect } from 'react'
import NormalLayout from '../components/NormalLayout'
import { PageModelTitle } from '../model/PageModel'
import bigLogo from '../static/images/ab-logo.png'
import { useIntl, Link } from 'gatsby-plugin-intl-v6'
import { arrDate } from '../utils/createTime'
import bannerCommunity from '../static/images/community-banner.png'
import { graphql, useStaticQuery } from 'gatsby'
import { getQueryVariable, updateQueryStringParameter } from '../utils/getQueryVariable'
import { Pagination, Skeleton } from 'antd'

export default Media

function Media() {
  let pageModel = new PageModelTitle('Media Reports', 'community-html')
  return <div>{NormalLayout(Main(), pageModel)}</div>
}

function Main() {
  const pageSize = 10
  const intl = useIntl()
  const currentLanguage = intl.locale

  const { allMdx } = useStaticQuery(graphql`
    query {
      allMdx (
        filter: { slug: { glob: "mediareport/*" } },
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            slug
            frontmatter {
              title
              date(formatString: "YYYY-MM-DD HH:mm")
              language
            }
          }
        }
      }
    }
  `)
  
  const [filteredData, paginationData] = useMemo(() => {
    const filtered = (allMdx?.edges || []).filter(
      (edge: any) => edge.node.frontmatter?.language === currentLanguage
    )
    
    const total = Math.ceil(filtered.length / pageSize)
    const pages = Array.from({ length: total }, (_, i) =>
      filtered.slice(i * pageSize, (i + 1) * pageSize)
    )
    
    return [filtered, pages]
  }, [allMdx?.edges, currentLanguage])
  
  const [currentPage, setCurrentPage] = useState(1)
  useEffect(() => {
    const queryPage = parseInt(getQueryVariable('page') || '1')
    const safePage = Math.min(Math.max(1, queryPage), paginationData.length)
    setCurrentPage(safePage)
  }, [paginationData.length])

  // 处理分页变化
  const handlePageChange = (page: number) => {
    if (typeof window === 'undefined') return
    const newUrl = updateQueryStringParameter(window.location.href, 'page', page)
    window.history.replaceState({ path: newUrl }, '', newUrl)
    
    setCurrentPage(page)
  }
  // 当前页数据
  const currentData = useMemo(() => {
    return paginationData[currentPage - 1] || []
  }, [currentPage, paginationData])

  return (
    <div>
      <div className='media-nav container'>
        <div className='nav-box'>
          <Link to={`/mediareports/`} className='active'>
            {intl.formatMessage({ id: 'Media Reports' })}
          </Link>
          <Link to={`/announcements/`}>
            {intl.formatMessage({ id: 'Announcements' })}
          </Link>
        </div>
      </div>
      <div className={'community-banner'}>
        <div className={'container banner'}>
          <span>{intl.formatMessage(
            { id: 'MediaReports.text' },
            { Reports: <span>{intl.formatMessage({ id: 'MediaReports.Reports' })}</span> }
          )}</span>
          <img className={'img'} src={bannerCommunity} alt="banner img" />
        </div>
      </div>
      <div className={'container community-data'}>
        <ul className={'data-list'}>
          {currentData.length > 0 ? (
            currentData.map((edge: any, index: number) => {
              const { frontmatter, slug } = edge.node
              return (
                <li key={`${slug}-${index}`}>
                  <Link to={`/${slug}`}>
                    <h3>{arrDate(frontmatter?.date, currentLanguage)}</h3>
                    <p>{frontmatter?.title}</p>
                  </Link>
                </li>
              )
            })
          ) : (
            Array(3)
              .fill(null)
              .map((_, i) => <Skeleton key={i} paragraph title active />)
          )}
        </ul>
        {paginationData.length > 1 && (
          <Pagination
            current={currentPage}
            total={filteredData.length}
            pageSize={pageSize}
            onChange={handlePageChange}
            showSizeChanger={false}
          />
        )}
      </div>
    </div>
  )
}